<template>
    <div class="container">
    <content-title :nav="nav"></content-title>
        <classList ref="classes" type="manage"></classList>
    </div>
</template>

<script>
import classList from '../outSchool/components/classList.vue';
export default {
    name: 'classManage',
    components:{
classList
    },
    data() {
        return {
              nav: { firstNav: "合作商管理", secondNav: "班级管理" },
        };
    },

    mounted() {
         this.$nextTick(()=>{
            this.$refs.classes.getList(1);
        });
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>

</style>